import { Button, Modal, Table, Alert, notification, message } from "antd";
import AssetCheckin from "../AssetCheckin/AssetCheckin";
import AssetDecon from "../AssetDecon/AssetDecon";
import history from "../../../../images/history.png";
import classes from "./AssetCheckinDecon.module.scss";
import { SetStateAction, useEffect, useState } from "react";
import { adminRootInstance } from "../../../../common/config/axios-config";
import { IRootState } from "../../../../common/redux/reducers";
import { connect } from "react-redux";
import { clearMessage } from "../../../../common/actions/common-actions";
import { fetchAllItems } from "../../items/actions";
import { fetchAllCampuses } from "../../campus/actions";
import {
  clearAllCheckin,
  fetchCheckInData,
  setCheckinFilterData,
} from "../AssetCheckin/actions";
import {
  clearAllDecon,
  fetchDeconData,
  setDeconFilterData,
} from "../AssetDecon/actions";
import {
  ALL_CAMPUS_ID,
  DECON_ASSET,
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
  PICKUP_READY_ASSET,
  SOILED_ASSET,
} from "../../../../common/shared-constants";
import { ExclamationCircleOutlined, BarcodeOutlined } from "@ant-design/icons";
import useScanner from "../hooks/useScanner";
import { validateIncorrectValue } from "../utils/utils";
import QuickActions from "./QuickActions";

export interface IRepairPayload {
  note: string;
  status: string;
  controlNumber: string;
  serialNumber: string;
}

const AssetCheckInDecon = ({
  fetchAllCampuses,
  allCampuses,
  campusLoading,
  fetchAllItems,
  allItems,

  fetchCheckInData,
  checkinAssets,
  checkinAssetsLoading,
  checkinErrorMessage,
  clearMessage,
  setCheckinFilterData = () => {},
  checkinFilterData,
  clearAllCheckin,

  fetchDeconData,
  deconAssets,
  deconAssetsLoading,
  deconErrorMessage,
  setDeconFilterData = () => {},
  deconFilterData,
  clearAllDecon,
}: any) => {
  const refetchCheckinAssets = (clearSearch = false) => {
    const localStore = localStorage.getItem(localFilterDataKeyCheckin);
    let filterValues = localStore
      ? JSON.parse(localStore)
      : checkinFilterInitialValues;
    fetchCheckInData({
      filter: filterValues,
      search: clearSearch ? "" : checkinSearch,
      limit: checkinLimit,
      page: checkinPage,
    });
  };

  const refetchDeconAssets = (clearSearch = false) => {
    const localStore = localStorage.getItem(localFilterDataKeyDecon);
    let filterValues = localStore
      ? JSON.parse(localStore)
      : deconFilterInitialValues;
    fetchDeconData({
      filter: filterValues,
      search: clearSearch ? "" : deconSearch,
      limit: deconLimit,
      page: deconPage,
    });
  };

  const [showLog, setShowLog] = useState(false);
  const [logs, setLogs] = useState([]);
  const [errorMsgLog, setErrorMsgLog] = useState("");
  const [showQuickFunctions, setShowQuickFunctions] = useState<any>(false);
  const [repairRequestTriggered, setRepaiRequestTriggered] =
    useState<boolean>(false);

  // -----------------------------CHECKIN STATES STARTS----------------------------------------
  const [tableOptCheckin, setOperationCheckin] = useState({
    search: "",
    limit: DEFAULT_PAGE_LIMIT,
    page: DEFAULT_PAGE,
    pageSizeOptions: ["30", "50", "100", "120"],
    showSizeChanger: true,
  });
  const {
    search: checkinSearch,
    limit: checkinLimit,
    page: checkinPage,
  } = tableOptCheckin;
  let checkinFilterInitialValues = {
    campus: ALL_CAMPUS_ID,
    item: "all",
    checkin: [],
  };
  const localFilterDataKeyCheckin = "aimAssetCheckInFilter";
  const [modalConfirmCheckin, setModalConfirmCheckin] = useState(false);
  const [checkinLoaders, checkinSetLoaders] = useState({ okayButton: false });
  const barcodeReadCheckin = useScanner();
  const [focusedTable, setFocusedTable] = useState("");
  const [errorMsgCheckin, setErrorMsgCheckin] = useState("");
  const campusFilterKeyCheckin = "campus";
  const locationFilterKeyCheckin = "item";
  const filterConfigCheckin = [
    {
      itemLabel: "Campus",
      itemName: campusFilterKeyCheckin,
      itemProps: {},
      fieldPlaceholder: "Select Campus",
      notFoundDesc: "No Campuses",
      loading: campusLoading,
      // fieldClassName: classes.campusSelect,
      fieldDisabled: () => {},
      fieldOnChange: () => {},
      data: allCampuses,
    },
    {
      itemLabel: "Check-in",
      itemName: "checkin",
      type: "daterange",
      itemProps: {},
      fieldPlaceholder: "Select Date",
      // fieldClassName: classes.campusSelect,
      fieldDisabled: () => {},
      fieldOnChange: () => {},
    },
    {
      itemLabel: "Equipment Name",
      itemName: locationFilterKeyCheckin,
      itemProps: {},
      fieldPlaceholder: "Select Equipment",
      notFoundDesc: "",
      // fieldClassName: classes.campusSelect,
      fieldDisabled: () => {},
      fieldOnChange: () => {},
      data: [{ id: "all", name: "All" }].concat(
        allItems
          .filter((item: any) => item.assetTracking)
          .map((item: any) => ({
            name: item.name,
            id: item.id,
          }))
      ),
    },
  ];

  // -----------------------------CHECKIN STATES ENDS----------------------------------------

  // --------------------------------------CHECKIN USE EFFECTS STARTS---------------------------------------------

  useEffect(() => {
    const timeout =
      errorMsgCheckin && setTimeout(() => setErrorMsgCheckin(""), 5000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [errorMsgCheckin]);

  useEffect(() => {
    const timeout = errorMsgLog && setTimeout(() => setErrorMsgLog(""), 5000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [errorMsgLog]);

  useEffect(() => {
    if (focusedTable === "checkin") {
      !modalConfirmCheckin && checkinAfterScan(barcodeReadCheckin);
    }
  }, [barcodeReadCheckin]);

  useEffect(() => {
    const localStore = localStorage.getItem(localFilterDataKeyCheckin);
    let filterValues = localStore
      ? JSON.parse(localStore)
      : checkinFilterInitialValues;
    (async () => {
      const campuses = await fetchAllCampuses();
      const items = await fetchAllItems(true);
      const validCampus = validateIncorrectValue({
        allData: campuses,
        key: campusFilterKeyCheckin,
        currentFilter: filterValues,
      });
      const validItem = validateIncorrectValue({
        allData: items,
        key: locationFilterKeyCheckin,
        currentFilter: filterValues,
      });
      let rectifiedFilter = {
        ...filterValues,
      };

      if (!validCampus || !validItem) {
        !validCampus &&
          (rectifiedFilter[campusFilterKeyCheckin] = ALL_CAMPUS_ID);
        !validItem && (rectifiedFilter[locationFilterKeyCheckin] = "all");
        localStorage.setItem(
          localFilterDataKeyCheckin,
          JSON.stringify(rectifiedFilter)
        );
      }
      setCheckinFilterData(rectifiedFilter);
      fetchCheckInData({ ...tableOptCheckin, filter: rectifiedFilter });
    })();
    return () => {
      clearAllCheckin();
    };
  }, [repairRequestTriggered]);

  // --------------------------------------CHECKIN USE EFFECTS ENDS---------------------------------------------

  // --------------------------------------CHECKIN FUNCTIONS STARTS---------------------------------------------

  const checkinFlow = async ({ controlNumber, override = false }: any) => {
    return await adminRootInstance
      .put(`aim/checkin/${controlNumber}`, {
        override,
      })
      .then((res: any) => {
        setErrorMsgCheckin("");
        return res.data;
      })
      .catch((err: any) => {
        let statusCode = err.response.status;
        if ([406].includes(statusCode)) {
          return { ...err.response.data, proceed: true };
        }
        setErrorMsgCheckin(
          err.response?.data?.message || "Something went wrong!"
        );
      });
  };

  const deconFlow = async ({ controlNumber, override = false }: any) => {
    return await adminRootInstance
      .put(`aim/decon/${controlNumber}`, {
        override,
      })
      .then((res: any) => {
        return res.data;
      })
      .catch((err: any) => {
        let statusCode = err.response.status;
        if ([406].includes(statusCode)) {
          return { ...err.response.data, proceed: true };
        }
        setErrorMsgDecon(
          err.response?.data?.message || "Something went wrong!"
        );
      });
  };

  const onSearchCheckin = (searchTerm: string) => {
    fetchCheckInData({
      filter: checkinFilterData,
      search: searchTerm.trim(),
      limit: checkinLimit,
      page: checkinPage,
    });
    setOperationCheckin({
      ...tableOptCheckin,
      search: searchTerm,
      page: DEFAULT_PAGE,
    });
  };

  const checkinAfterScan = async (scannedCode: string) => {
    setOperationCheckin({
      ...tableOptCheckin,
      search: scannedCode,
      page: DEFAULT_PAGE,
    });
    document.getElementById("hiddenInputCheckin")?.focus();
    if (scannedCode) {
      notification.info({
        message: (
          <span className={classes.notification}>Data read: {scannedCode}</span>
        ),
        placement: "bottomRight",
        duration: 2,
        icon: <BarcodeOutlined />,
        style: { width: "max-content" },
        onClose: () => document.getElementById("hiddenInputCheckin")?.focus(),
      });
      //First api without override is hit
      const response: any = await checkinFlow({ controlNumber: scannedCode });
      if (!response) {
        return onSearchCheckin("");
      }
      if (response.status === "success") {
        //if successfull - show message
        setErrorMsgCheckin("");
        message.success(
          `Asset moved to ${response.data.status} status sucessfully.`
        );
        setCheckinFilterData(checkinFilterInitialValues);
        localStorage.setItem(
          localFilterDataKeyCheckin,
          JSON.stringify({ ...checkinFilterInitialValues })
        );
        onSearchCheckin(scannedCode);
      } else if (response.proceed && response.status === "error") {
        //if error
        if (!response.currentStatus) {
          return setErrorMsgCheckin(response.message);
        }
        //if already in soiled status
        if (
          response.currentStatus === SOILED_ASSET ||
          response.currentStatus === PICKUP_READY_ASSET
        ) {
          setCheckinFilterData(checkinFilterInitialValues);
          localStorage.setItem(
            localFilterDataKeyCheckin,
            JSON.stringify({ ...checkinFilterInitialValues })
          );
          onSearchCheckin(scannedCode);
          setErrorMsgCheckin(
            `Asset already moved to ${response.currentStatus} status!`
          );
          return;
        }
        //If status is other than SOILED or INUSE
        if (!modalConfirmCheckin) {
          //to prevent simultaneous popup of modal
          setModalConfirmCheckin(true);
          Modal.confirm({
            title: `Move asset to ${SOILED_ASSET} status?`,
            icon: (
              <ExclamationCircleOutlined
                style={{ color: "rgba(0, 0, 0, 0.85)" }}
              />
            ),
            className: "sharpDelayModal",
            content: (
              <span>
                <b>{scannedCode}</b> is in <b>{response.currentStatus}</b>{" "}
                status. <br />
                Do you want to move it to <b>{SOILED_ASSET}</b> status?
              </span>
            ),
            okText: "Move it",
            cancelText: "Don't move",
            width: "max-content",
            okButtonProps: {
              loading: checkinLoaders.okayButton,
              className: "nextBtn",
              onFocus: (e: any) => e.target.blur(),
            },
            cancelButtonProps: {
              className: "backBtn",
              onFocus: (e: any) => e.target.blur(),
            },
            afterClose: () => {
              setModalConfirmCheckin(false);
              document.getElementById("hiddenInputCheckin")?.focus();
            },
            onOk: async () => {
              checkinSetLoaders((loaders: any) => ({
                ...loaders,
                okayButton: true,
              }));
              const response: any = await checkinFlow({
                controlNumber: scannedCode,
                override: true,
              });
              if (response?.status === "success") {
                setErrorMsgCheckin("");
                setCheckinFilterData(checkinFilterInitialValues);
                localStorage.setItem(
                  localFilterDataKeyCheckin,
                  JSON.stringify({ ...checkinFilterInitialValues })
                );
                onSearchCheckin(scannedCode);
                message.success("Asset moved successfully!!");
                // Re-fetching updated Decon assets starts
                localStorage.setItem(
                  localFilterDataKeyCheckin,
                  JSON.stringify({ ...checkinFilterInitialValues })
                );
                setOperationCheckin({
                  ...tableOptCheckin,
                  search: "",
                });
                refetchDeconAssets();
                refetchCheckinAssets(true);
                // Re-fetching updated Decon assets ends
              } else
                setErrorMsgCheckin(
                  response?.message || "Something went wrong!"
                );
              checkinSetLoaders((loaders: any) => ({
                ...loaders,
                okayButton: false,
              }));
            },
            onCancel: () => {
              setErrorMsgCheckin("");
              onSearchCheckin("");
            },
          });
        }
      }
    }
    // Re-fetching updated Decon assets starts
    setOperationCheckin({
      ...tableOptCheckin,
      search: "",
    });
    refetchDeconAssets();
    refetchCheckinAssets(true);
    // Re-fetching updated Decon assets ends
  };

  const setDeconToRepair = async (
    repairRequest: IRepairPayload,
    assetId: any
  ) => {
    const response = await adminRootInstance
      .put(`aim/repair/${assetId}`, repairRequest)
      .then((res: any) => {
        return res.data;
      })
      .catch((err: any) => {
        let statusCode = err.response.status;
        if ([406].includes(statusCode)) {
          return { ...err.response.data, proceed: true };
        }
        setErrorMsgDecon(
          err.response?.data?.message || "Something went wrong!"
        );
      });

    if (response.status === "success") {
      //if successfull - show message
      setErrorMsgDecon("");
      message.success("Asset moved successfully!!");
      // Re-fetching updated Decon assets starts
      localStorage.setItem(
        localFilterDataKeyCheckin,
        JSON.stringify({ ...checkinFilterInitialValues })
      );
      setOperationCheckin({
        ...tableOptCheckin,
        search: "",
      });
      refetchDeconAssets();
      refetchCheckinAssets(!repairRequestTriggered);
    }
    setRepaiRequestTriggered(true);
  };

  const manualScanInputCheckin = (e: any) => {
    if (
      (e.keyCode === 10 || e.keyCode === 13 || e.key === "Enter") &&
      (e.ctrlKey || e.metaKey)
    ) {
      checkinAfterScan(checkinSearch);
    }
  };

  const manualSubmitCheckin = () => {
    checkinAfterScan(checkinSearch);
  };

  const onFilterSubmitCheckin = (filterValues: any) => {
    setErrorMsgCheckin("");
    setCheckinFilterData(filterValues);
    setOperationCheckin({
      ...tableOptCheckin,
      page: DEFAULT_PAGE,
    });
    fetchCheckInData({
      filter: filterValues,
      search: checkinSearch,
      limit: checkinLimit,
      page: DEFAULT_PAGE,
    });
    setCheckinFilterData(filterValues);
    localStorage.setItem(
      localFilterDataKeyCheckin,
      JSON.stringify({ ...filterValues })
    );
  };

  const onFilterResetCheckin = () => {
    setErrorMsgCheckin("");
    setOperationCheckin({
      ...tableOptCheckin,
      page: DEFAULT_PAGE,
    });
    fetchCheckInData({
      filter: checkinFilterInitialValues,
      search: checkinSearch,
      limit: checkinLimit,
      page: DEFAULT_PAGE,
    });
    localStorage.removeItem(localFilterDataKeyCheckin);
    setCheckinFilterData(checkinFilterInitialValues);
  };

  // --------------------------------------CHECKIN FUNCTIONS ENDS---------------------------------------------

  // --------------------------------------DECON STATES STARTS-------------------------------------------------------

  const [tableOptDecon, setOperationDecon] = useState({
    search: "",
    limit: DEFAULT_PAGE_LIMIT,
    page: DEFAULT_PAGE,
    pageSizeOptions: ["30", "50", "100", "120"],
    showSizeChanger: true,
  });
  const {
    search: deconSearch,
    limit: deconLimit,
    page: deconPage,
  } = tableOptDecon;
  let deconFilterInitialValues = {
    campus: ALL_CAMPUS_ID,
    checkin: [],
    decon: [],
  };
  const localFilterDataKeyDecon = "aimAssetDeconFilter";
  const [deconModalConfirm, deconSetModalConfirm] = useState(false);
  const [deconLoaders, deconSetLoaders] = useState({ okayButton: false });
  const barcodeReadDecon = useScanner();
  const campusFilterKeyDecon = "campus";

  // --------------------------------------DECON STATES ENDS-------------------------------------------------------

  // --------------------------------------DECON USE EFFECTS STARTS-------------------------------------------------------

  useEffect(() => {
    if (focusedTable === "decon") {
      !deconModalConfirm && deconAfterScan(barcodeReadDecon);
    }
  }, [barcodeReadDecon]);
  const [errorMsgDecon, setErrorMsgDecon] = useState("");

  useEffect(() => {
    const timeout =
      errorMsgDecon && setTimeout(() => setErrorMsgDecon(""), 5000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [errorMsgDecon]);

  useEffect(() => {
    const localStore = localStorage.getItem(localFilterDataKeyDecon);
    let filterValues = localStore
      ? JSON.parse(localStore)
      : deconFilterInitialValues;
    (async () => {
      //check if applied campus filter is a valid campus
      const campuses = await fetchAllCampuses();
      const validCampus = validateIncorrectValue({
        allData: campuses,
        key: campusFilterKeyDecon,
        currentFilter: filterValues,
      });
      let rectifiedFilter = {
        ...filterValues,
      };
      if (!validCampus) {
        !validCampus && (rectifiedFilter[campusFilterKeyDecon] = ALL_CAMPUS_ID);
        localStorage.setItem(
          localFilterDataKeyDecon,
          JSON.stringify(rectifiedFilter)
        );
      }
      setDeconFilterData(rectifiedFilter);
      fetchDeconData({ ...tableOptDecon, filter: rectifiedFilter });
    })();
    return () => {
      clearAllDecon();
    };
  }, []);

  // --------------------------------------DECON USE EFFECTS ENDS-------------------------------------------------------

  // --------------------------------------DECON FUNCTIONS STARTS-------------------------------------------------------

  const onSearchDecon = (searchTerm: string) => {
    // setErrorMsg("");
    fetchDeconData({
      filter: deconFilterData,
      search: searchTerm.trim(),
      limit: deconLimit,
      page: deconPage,
    });
    setOperationDecon({
      ...tableOptDecon,
      search: searchTerm,
      page: DEFAULT_PAGE,
    });
  };

  const deconAfterScan = async (scannedCode: any, quickFunction: boolean = false) => {
    setOperationDecon({
      ...tableOptDecon,
      search: scannedCode,
      page: DEFAULT_PAGE,
    });
    document.getElementById("hiddenInputDecon")?.focus();
    if (scannedCode) {
      notification.info({
        message: (
          <span className={classes.notification}>Data read: {scannedCode}</span>
        ),
        placement: "bottomRight",
        duration: 2,
        icon: <BarcodeOutlined />,
        style: { width: "max-content" },
        onClose: () => document.getElementById("hiddenInputDecon")?.focus(),
      });

      //First api without override is hit
      const response: any = await deconFlow({ controlNumber: scannedCode });
      if (!response) {
        return onSearchDecon("");
      }
      if (response.status === "success") {
        //if successfull - show message
        setErrorMsgDecon("");
        message.success(
          `Asset moved to ${response.data.status} status sucessfully.`
        );
        setDeconFilterData(deconFilterInitialValues);
        localStorage.setItem(
          localFilterDataKeyDecon,
          JSON.stringify({ ...deconFilterInitialValues })
        );
        onSearchDecon(!quickFunction ? scannedCode : ""); 
      } else if (response.proceed && response.status === "error") {
        //if error
        if (!response.currentStatus) {
          return setErrorMsgDecon(response.message);
        }
        //if already in soiled status
        if (response.currentStatus === SOILED_ASSET) {
          setDeconFilterData(deconFilterInitialValues);
          localStorage.setItem(
            localFilterDataKeyDecon,
            JSON.stringify({ ...deconFilterInitialValues })
          );
          onSearchDecon(scannedCode);
          setErrorMsgDecon(`Asset already moved to ${SOILED_ASSET} status!`);
          return;
        }

        //If status is other than SOILED or DECON
        if (!deconModalConfirm) {
          //to prevent simultaneous popup of modal
          deconSetModalConfirm(true);
          Modal.confirm({
            title: `Move asset to ${DECON_ASSET} status`,
            icon: (
              <ExclamationCircleOutlined
                style={{ color: "rgba(0, 0, 0, 0.85)" }}
              />
            ),
            className: "sharpDelayModal",
            width: "max-content",
            content: (
              <span>
                <b>{scannedCode}</b> is in <b>{response.currentStatus}</b>{" "}
                status. <br />
                Do you want to move it to <b>{DECON_ASSET}</b> status?
              </span>
            ),
            okText: "Move it",
            cancelText: "Don't move",
            okButtonProps: {
              loading: deconLoaders.okayButton,
              className: "nextBtn",
              onFocus: (e: any) => e.target.blur(),
            },
            cancelButtonProps: {
              className: "backBtn",
              onFocus: (e: any) => e.target.blur(),
            },
            afterClose: () => {
              deconSetModalConfirm(false);
              document.getElementById("hiddenInputDecon")?.focus();
            },
            onOk: async () => {
              deconSetLoaders((loaders: any) => ({
                ...loaders,
                okayButton: true,
              }));
              const response: any = await deconFlow({
                controlNumber: scannedCode,
                override: true,
              });
              if (response.status === "success") {
                setErrorMsgDecon("");
                setDeconFilterData(deconFilterInitialValues);
                localStorage.setItem(
                  localFilterDataKeyDecon,
                  JSON.stringify({ ...deconFilterInitialValues })
                );
                onSearchDecon(scannedCode);
                message.success("Asset moved successfully!!");
                // Re-fetching updated checkin assets starts
                setOperationDecon({
                  ...tableOptDecon,
                  search: "",
                });
                refetchCheckinAssets();
                refetchDeconAssets(true);
                // Re-fetching updated checkin assets ends
              } else setErrorMsgDecon(response.message);
              deconSetLoaders((loaders: any) => ({
                ...loaders,
                okayButton: false,
              }));
            },
            onCancel: () => {
              onSearchDecon("");
              setErrorMsgDecon("");
            },
          });
        }
      }
    }
    // Re-fetching updated checkin assets starts
    setOperationDecon({
      ...tableOptDecon,
      search: "",
    });
    refetchCheckinAssets();
    refetchDeconAssets(true);
    if(showQuickFunctions.type == 'checkin'){
      setShowQuickFunctions({...showQuickFunctions , type: 'decon'} )
    }else{
      setShowQuickFunctions(null)
    }
    // Re-fetching updated checkin assets ends
  };

  const manualScanInputDecon = (e: any) => {
    if (
      (e.keyCode === 10 || e.keyCode === 13 || e.key === "Enter") &&
      (e.ctrlKey || e.metaKey)
    ) {
      deconAfterScan(deconSearch);
    }
  };

  const manualSubmitDecon = () => {
    deconAfterScan(deconSearch);
  };

  const filterConfigDecon = [
    {
      itemLabel: "Campus",
      itemName: campusFilterKeyDecon,
      itemProps: {},
      fieldPlaceholder: "Select Campus",
      notFoundDesc: "No Campuses",
      loading: campusLoading,
      // fieldClassName: classes.campusSelect,
      fieldDisabled: () => {},
      fieldOnChange: () => {},
      data: allCampuses,
    },
    {
      itemLabel: "Check-in",
      itemName: "checkin",
      type: "daterange",
      itemProps: {},
      fieldPlaceholder: "Select Date",
      // fieldClassName: classes.campusSelect,
      fieldDisabled: () => {},
      fieldOnChange: () => {},
    },
    {
      itemLabel: "Decon Start",
      itemName: "decon",
      type: "daterange",
      itemProps: {},
      fieldPlaceholder: "Select Date",
      fieldDisabled: () => {},
      fieldOnChange: () => {},
    },
  ];

  const onFilterSubmitDecon = (filterValues: any) => {
    setErrorMsgDecon("");
    setDeconFilterData(filterValues);
    setOperationDecon({
      ...tableOptDecon,
      page: DEFAULT_PAGE,
    });
    fetchDeconData({
      filter: filterValues,
      search: deconSearch,
      limit: deconLimit,
      page: DEFAULT_PAGE,
    });
    setDeconFilterData(filterValues);
    localStorage.setItem(
      localFilterDataKeyDecon,
      JSON.stringify({ ...filterValues })
    );
  };

  const onFilterResetDecon = () => {
    setErrorMsgDecon("");
    setOperationDecon({
      ...tableOptDecon,
      page: DEFAULT_PAGE,
    });
    fetchDeconData({
      filter: deconFilterInitialValues,
      search: deconSearch,
      limit: deconLimit,
      page: DEFAULT_PAGE,
    });
    localStorage.removeItem(localFilterDataKeyDecon);
    setDeconFilterData(deconFilterInitialValues);
  };

  // --------------------------------------DECON FUNCTIONS ENDS-------------------------------------------------------

  useEffect(() => {}, []);
  //Log Table Columns
  const commonObj = {
    align: "center",
    ellipsis: true,
  };
  const columns: any = [
    {
      ...commonObj,
      title: "",
      dataIndex: "log",
      key: "key",
      width: 65,
    },
  ];

  const fetchLogs = async () => {
    await adminRootInstance
      .get(`aim/history`)
      .then((res: any) => {
        setErrorMsgLog("");
        setLogs(res?.data?.data);
        setShowLog(true);
      })
      .catch((err: any) => {
        setErrorMsgLog(err.response?.data?.message || "Something went wrong!");
      });
  };

  return (
    <div style={{ width: "100%", margin: 0 }}>
      <QuickActions
        handleClose={() => setShowQuickFunctions(null)}
        type={showQuickFunctions?.type}
        asset={showQuickFunctions?.record}
        handleStartDecon={(cntrlNumb: string) => deconAfterScan(cntrlNumb, true)}
        visible={showQuickFunctions ? true : false}
        startDecon={() =>
          deconAfterScan(showQuickFunctions?.record?.controlNumber, true)
        }
        setShowQuickFunctions={setShowQuickFunctions}
        stopDecon={() =>
          deconAfterScan(showQuickFunctions?.record?.controlNumber, true)
        }
        setDeconToRepair={setDeconToRepair}
      />
      <AssetCheckin
        fetchCheckInData={fetchCheckInData}
        checkinAssets={checkinAssets}
        setShowQuickFunctions={(record: any) =>
          setShowQuickFunctions({ type: "checkin", record: record })
        }
        checkinAssetsLoading={checkinAssetsLoading}
        checkinErrorMessage={checkinErrorMessage}
        clearMessage={clearMessage}
        checkinFilterData={checkinFilterData}
        allCampuses={allCampuses}
        setErrorMsgCheckin={setErrorMsgCheckin}
        errorMsgCheckin={errorMsgCheckin}
        manualScanInputCheckin={manualScanInputCheckin}
        barcodeReadCheckin={barcodeReadCheckin}
        filterConfigCheckin={filterConfigCheckin}
        tableOptCheckin={tableOptCheckin}
        setOperationCheckin={setOperationCheckin}
        checkinSearch={checkinSearch}
        onFilterSubmitCheckin={onFilterSubmitCheckin}
        onFilterResetCheckin={onFilterResetCheckin}
        onSearchCheckin={onSearchCheckin}
        checkinFilterInitialValues={checkinFilterInitialValues}
        manualSubmitCheckin={manualSubmitCheckin}
      />
      <AssetDecon
        fetchDeconData={fetchDeconData}
        deconAssets={deconAssets}
        setShowQuickFunctions={(record: any) =>
          setShowQuickFunctions({ type: "decon", record: record })
        }
        deconAssetsLoading={deconAssetsLoading}
        deconErrorMessage={deconErrorMessage}
        clearMessage={clearMessage}
        deconFilterData={deconFilterData}
        allCampuses={allCampuses}
        onSearchDecon={onSearchDecon}
        onFilterSubmitDecon={onFilterSubmitDecon}
        onFilterResetDecon={onFilterResetDecon}
        errorMsgDecon={errorMsgDecon}
        setErrorMsgDecon={setErrorMsgDecon}
        filterConfigDecon={filterConfigDecon}
        tableOptDecon={tableOptDecon}
        setOperationDecon={setOperationDecon}
        deconSearch={deconSearch}
        deconFilterInitialValues={deconFilterInitialValues}
        barcodeReadDecon={barcodeReadDecon}
        manualScanInputDecon={manualScanInputDecon}
        manualSubmitDecon={manualSubmitDecon}
      />
      <Button
        htmlType="button"
        className={classes.filterBtn}
        onClick={fetchLogs}
        style={deconAssets?.assets?.length > 0 ? {} : { marginTop: "16px" }}
      >
        <img src={history} alt="filterIcon" className={classes.btnImg} />
      </Button>

      <Modal
        visible={showLog}
        onCancel={() => setShowLog(false)}
        maskClosable={true}
        footer={null}
        width={600}
        title="Asset Scan Log"
      >
        <Table
          className={classes.mainTable}
          dataSource={logs}
          columns={columns}
          size={"large"}
          locale={{ filterEmptyText: true }}
          bordered
          showHeader={false}
          pagination={false}
          scroll={{ y: 500 }}
        />
      </Modal>

      {errorMsgLog && (
        <Alert
          className={`fadeIn ${classes.alertContainer}`}
          message={errorMsgLog}
          type="error"
          closable
          afterClose={() => setErrorMsgLog("")}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({
  aimAssetCheckInState,
  aimAssetDeconState,

  campusManagement,
  itemManagement,
}: IRootState) => ({
  checkinAssets: aimAssetCheckInState.assets,
  checkinAssetsLoading: aimAssetCheckInState.assetsLoading,
  checkinErrorMessage: aimAssetCheckInState.errorMessage,
  checkinFilterData: aimAssetCheckInState.filterData,

  deconAssets: aimAssetDeconState.assets,
  deconAssetsLoading: aimAssetDeconState.assetsLoading,
  deconErrorMessage: aimAssetDeconState.errorMessage,
  deconFilterData: aimAssetDeconState.filterData,

  allCampuses: campusManagement.allCampuses,
  campusLoading: campusManagement.loading,
  allItems: itemManagement.allItems,
});
const mapDispatchToProps = {
  fetchAllItems,
  fetchAllCampuses,
  clearMessage,

  setCheckinFilterData,
  fetchCheckInData,
  clearAllCheckin,

  setDeconFilterData,
  fetchDeconData,
  clearAllDecon,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetCheckInDecon);
