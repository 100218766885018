/* eslint-disable react-hooks/exhaustive-deps*/
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Popover,
  Row,
  Table,
  Radio,
  Typography,
  Input,
  Alert,
  DatePicker,
  Form,
  Switch,
  Select,
  notification,
  Tooltip,
  Empty,
} from "antd";
import { Helmet } from "react-helmet";
import classes from "./dashboard.module.scss";
import Timer from "./countdown";
import {
  CANCELLED,
  COMPLETED,
  ALL_CAMPUS_ID,
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
  SUB_ROOM2,
  UNASSIGNED,
  UNSUB_ROOM2,
  OVERRIDE_CLOSE,
  CATALOGUE_ALL,
  BUILDING_ALL,
  ADMIN_DATE_FORMAT,
} from "../../../common/shared-constants";
import { IRootState } from "../../../common/redux/reducers";
import { connect, useDispatch } from "react-redux";
import {
  fetchOrders,
  fetchSummaryCounts,
  fetchCampuses,
  fetchBuildings,
  updateOrder,
  addNewOrderToSummaryCount,
} from "./actions";
import { clearMessage } from "../../../common/actions/common-actions";
import {
  CLEAR_ORDERS_FETCH_ERROR_MSG,
  FETCH_ORDERS_START,
} from "./action-types";
import filterImg from "../../../images/filter.png";
import sigmaImg from "../../../images/sigma.png";
import searchImg from "../../../images/search-icon.png";
import Summary from "./summary";
import { useHistory } from "react-router-dom";
import {
  getMySocket,
  subscribeToRoom,
  unSubscribeToRoom,
} from "../../../common/utils/socket";
import moment, { Moment } from "moment";
import { getCatalogueIdByRequestType } from "../../../common/utils/catalogue";
import ReassignUserModal from "./request-view/reassignUserModal/ReassignUserModal";

const { Title } = Typography;
const summaryConfig = [
  { label: "Open Requests", datakey: "open" },
  { label: "Started Requests", datakey: "started" },
  { label: "Assigned Requests", datakey: "assigned" },
  { label: "Delayed Requests", datakey: "delayed" },
  { label: "Accepted Requests", datakey: "accepted" },
  { label: "Completed Requests", datakey: "completed" },
  { label: "Cancelled Requests", datakey: "cancelled" },
  { label: "Overrode & Closed", datakey: "override close" },
];
const DashBoardPage = ({
  fetchOrders,
  fetchSummaryCounts,
  orders,
  ordersLoading,
  totalCount,
  fetchErrorMsg,
  clearMessage,
  summary,
  errorMessage,
  user,
  campuses,
  buildings,
  fetchCampuses,
  fetchBuildings,
  updateOrder,
  dashboardState,
  addNewOrderToSummaryCount,
}: any) => {
  const dispatch = useDispatch();
  const [tableOpt, setOperation] = useState({
    search: "",
    limit: DEFAULT_PAGE_LIMIT,
    page: DEFAULT_PAGE,
    pageSizeOptions: ["30", "50", "100", "120"],
    showSizeChanger: true,
  });
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const history = useHistory();
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [allOrderForPt, setAllOrderForPt] = useState(false);

  const { search, page, limit, showSizeChanger, pageSizeOptions } = tableOpt;
  let retrievedObject: any = {
    catalogue: CATALOGUE_ALL,
    campusId: ALL_CAMPUS_ID,
    building: [],
    dateRange: [],
    allOrder: false,
  };
  const [filteredValue, setFilteredValue] = useState({ ...retrievedObject });
  const [tempFilterValues, setTempFilterValues] = useState<any>();
  const [socketEditResponse, setSocketEditResponse] = useState<any>();
  const [socketAddResponse, setSocketAddResponse] = useState<any>();
  const [showQuickAssignModal, setShowQuickAssignModal] = useState<any>({});

  const newOrderListener = (res: any) => {
    setSocketAddResponse(res);
  };

  const updatedOrderListener = (res: any) => {
    setSocketEditResponse(res);
  };

  const initSocket = async () => {
    const connect = await subscribeToRoom(SUB_ROOM2);
    if (connect) {
      const socket = getMySocket();
      socket.on("new-order", newOrderListener);
      socket.on("updated-order", updatedOrderListener);
    }
  };
  const [buildingList, setBuildingList] = useState<any>([]);
  const [filterCampus, setFilterCampus] = useState<any>();
  useEffect(() => {
    if (filterCampus === ALL_CAMPUS_ID) {
      setBuildingList(buildings);
    } else {
      const campusSpecificBuildings: any = buildings.filter(
        (building: any) => building.campusId === filterCampus
      );
      if (campusSpecificBuildings.length) {
        setBuildingList(campusSpecificBuildings);
      } else {
        setBuildingList([]);
      }
    }
  }, [filterCampus, buildings]);
  useEffect(() => {
    form.setFieldsValue({ building: "", campusId: ALL_CAMPUS_ID });
    const jsonString: any = localStorage.getItem("filterValues");
    var tempValues = JSON.parse(localStorage.getItem("filterValues")!);
    if (tempValues?.catalogue === "Transport") {
      setAllOrderForPt(true);
    }
    retrievedObject = JSON.parse(jsonString)
      ? JSON.parse(jsonString)
      : retrievedObject;
    fetchSummaryCounts();
    const fetchData = async () => {
      dispatch({ type: FETCH_ORDERS_START });
      const campuses = await fetchCampuses();
      const isCampusIDFilter = campuses?.campuses?.filter(
        (campus: any) => campus.id === retrievedObject.campusId
      );
      const campusFilterValue =
        isCampusIDFilter?.length > 0 ? retrievedObject.campusId : ALL_CAMPUS_ID;
      const { buildings } = await fetchBuildings();
      let buildingFilterValue;
      let existingBuilding;
      if (campusFilterValue === ALL_CAMPUS_ID) {
        buildingFilterValue = [];
      } else {
        const campusSpecificBuildings = buildings.filter(
          (building: any) => building.campusId === campusFilterValue
        );
        if (campusSpecificBuildings.length) {
          existingBuilding = campusSpecificBuildings.find(
            ({ buildingName }: any) => retrievedObject.building.includes(buildingName)
          );
          buildingFilterValue = existingBuilding
            ? retrievedObject.building
            : [];
        } else {
          buildingFilterValue = [];
        }
      }

      setOperation({
        ...tableOpt,
        page: DEFAULT_PAGE,
      });
      setFilteredValue({
        ...retrievedObject,
        building: buildingFilterValue,
        campusId: campusFilterValue,
        dateRange: retrievedObject.dateRange?.map((date: any) => moment(date)),
      });
      setFilterCampus(campusFilterValue);
      form.setFieldsValue({
        ...retrievedObject,
        building: buildingFilterValue,
        campusId: campusFilterValue,
        dateRange: retrievedObject.dateRange?.map((date: any) => moment(date)),
      });
      fetchOrders({
        filter: {
          ...retrievedObject,
          building: buildingFilterValue.length ? buildingFilterValue : [],
          campusId: campusFilterValue,
        },
        search,
        limit,
        page: DEFAULT_PAGE,
      });
      localStorage.setItem(
        "filterValues",
        JSON.stringify({
          ...retrievedObject,
          building: buildingFilterValue,
          campusId: campusFilterValue,
        })
      );
    };
    fetchData();
    initSocket();
    return () => {
      const socket = getMySocket();
      clearFetchError();
      unSubscribeToRoom(UNSUB_ROOM2);
      socket.off("new-order", newOrderListener);
      socket.off("updated-order", updatedOrderListener);
    };
  }, []);
  //for new order creation
  useEffect(() => {
    const catalogueNameMap: any = {
      1: "Equipment",
      2: "Sharps",
      3: "Transport",
    };
    if (socketAddResponse) {
      const { catalogue, campusId, buildingName: building } = filteredValue;
      if (catalogue === "Transport") {
        setAllOrderForPt(true);
      }
      let catalogueId =
        getCatalogueIdByRequestType(socketAddResponse.requestTypeId) || 0;
      addNewOrderToSummaryCount(socketAddResponse);
      if (
        !(
          catalogue !== CATALOGUE_ALL &&
          catalogueNameMap[catalogueId] !== catalogue
        ) &&
        !(
          campusId &&
          campusId !== 1 &&
          campusId !== socketAddResponse.campusId
        ) &&
        !(
          building &&
          building !== "all" &&
          building !== socketAddResponse.buildingName
        )
      ) {
        fetchOrders(
          {
            filter: filteredValue,
            search,
            limit,
            page,
          },
          true
        );
        notification.success({
          message: `New Order ${socketAddResponse.reqId} Created`,
          placement: "bottomRight",
        });
      }
    }
  }, [socketAddResponse]);

  //for edit and status update
  useEffect(() => {
    if (socketEditResponse) {
      let oldStatus, newStatus;
      let oldOrder = dashboardState.orders.find((oldOrder: any) => {
        let newOrder = socketEditResponse?.order
          ? socketEditResponse.order
          : socketEditResponse;
        return oldOrder.reqId === newOrder.reqId;
      }); //getting the order that is to be updated
      //especially for order status update
      if (socketEditResponse.oldStatus && socketEditResponse.newStatus) {
        oldStatus = socketEditResponse?.oldStatus.toLowerCase();
        newStatus = socketEditResponse?.newStatus.toLowerCase();
        //since there are no unassgined status and only open status
        oldStatus = oldStatus === UNASSIGNED.toLowerCase() ? "open" : oldStatus;
        newStatus = newStatus === UNASSIGNED.toLowerCase() ? "open" : newStatus;
      } else {
        oldStatus = oldOrder?.status?.toLowerCase();
        newStatus = socketEditResponse?.status?.toLowerCase();
      }
      let finalOrders = [];
      if (
        (socketEditResponse.newStatus === CANCELLED ||
          socketEditResponse.newStatus === COMPLETED ||
          socketEditResponse.newStatus === OVERRIDE_CLOSE) &&
        !filteredValue.allOrder
      ) {
        finalOrders = dashboardState.orders.filter(
          (order: any) => order.id !== socketEditResponse?.order?.id
        );
      } //for details update
      else
        finalOrders = dashboardState.orders.map((order: any) => {
          return order.id ===
            (socketEditResponse?.order?.id || socketEditResponse.id)
            ? socketEditResponse.order || socketEditResponse
            : order;
        });
      //finally setting the object in redux
      updateOrder({
        orders: finalOrders,
        oldStatus,
        newStatus,
      });
    }
  }, [socketEditResponse]);

  const searchClick = () => {
    setSearchEnabled(!searchEnabled);
  };
  const clearFetchError = () => {
    clearMessage(CLEAR_ORDERS_FETCH_ERROR_MSG);
  };

  const onSearch = (e: any) => {
    setOperation({
      ...tableOpt,
      search: e.target.value,
      page: DEFAULT_PAGE,
    });
    fetchOrders({
      filter: filteredValue,
      search: e.target.value.trim(),
      limit,
      page: DEFAULT_PAGE,
    });
  };
  const onView = (order: any) => {
    history.push(`${window.location.pathname}/order/${order.reqId}`);
  };

  const columns: any = [
    {
      align: "center",
      title: "Actions",
      key: "action",
      width: "11%",
      render: (record: any) => {
        const { status } = record;
        return (
          <Row gutter={[3, 3]} style={{ justifyContent: "center" }}>
            <Col>
              <Button
                className="nextBtn"
                size="small"
                onClick={() => {
                  onView(record);
                }}
              >
                View
              </Button>
            </Col>
            {!(
              status === COMPLETED ||
              status === CANCELLED ||
              status === OVERRIDE_CLOSE
            ) && (
                <Col>
                  <Button
                    className="nextBtn"
                    size="small"
                    style={{ margin: "0px 3px" }}
                    onClick={(e) => {
                      setShowQuickAssignModal(record);
                    }}
                  >
                    Assign
                  </Button>
                </Col>
              )}
          </Row>
        );
      },
      responsive: ["lg", "md"],
    },
    {
      align: "center",
      title: "Request ID",
      width: "15%",
      dataIndex: "reqId",
      key: "name",
      render: (reqId: any) => reqId,
      ellipsis: true,
    },
    // {
    //   align: "center",
    //   width: "10%",
    //   title: "Date&Time",
    //   dataIndex: "dateTime",
    //   key: "dateTime",
    //   render: (dateTime: any) => {
    //     dateTime = convertTZ(dateTime, "America/New_York");
    //     return `${
    //       dateTime.getMonth() + 1
    //     }/${dateTime.getDate()}/${dateTime.getFullYear()} ${dateTime.getHours()}:${dateTime.getMinutes()}:${dateTime.getSeconds()}`;
    //   },
    // },
    {
      align: "center",
      width: "10%",
      title: "Request Type",
      dataIndex: "requestType",
      key: "requestType",
      render: (requestType: any) => requestType,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Priority/Destination",
      width: "10%",
      dataIndex: "priority",
      key: "priority",
      render: (priority: any, record: any) => {
        return (
          <div className={classes.priority}>
            <div
              style={{ backgroundColor: priority?.color }}
              className={classes.priorityColor}
            ></div>
            <div
              title={priority ? priority.name : record.destinationUnit}
              className={`${classes.priorityText} priorityText`}
            >
              {priority ? priority.name : record.destinationUnit || "-"}
            </div>
          </div>
        );
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Status",
      width: "10%",
      dataIndex: "status",
      key: "status",
      render: (status: any, record: any) => {
        return record.orderExpired ? "EXPIRED" : status;
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Assigned to",
      dataIndex: "assignedTo",
      key: "assignedTo",
      width: "10%",
      ellipsis: true,
      render: (assignedTo: any) => (assignedTo ? assignedTo : "-"),
    },
    {
      align: "center",
      title: "Time Left",
      dataIndex: "timeLeft",
      key: "timeLeft",
      width: "10%",
      className: "timeLeft",
      render: (timeLeft: any, record: any) => {
        return (
          <div className={classes.timer}>
            {record.status !== CANCELLED &&
              record.status !== OVERRIDE_CLOSE &&
              record.status !== COMPLETED ? (
              <Timer
                timeLeft={
                  new Date(record.dateTime).getTime() + record.timeLimit * 60000
                }
                record={record}
              />
            ) : (
              <span className={"noAlert"}>-</span>
            )}
          </div>
        );
      },
      ellipsis: true,
    },
    {
      align: "center",
      width: "10%",
      title: "Building",
      dataIndex: "building",
      key: "building",
      render: (building: string) => building,
      ellipsis: true,
    },
    {
      align: "center",
      width: "10%",
      title: "Unit/Origin",
      dataIndex: "unit",
      key: "unit",
      render: (unit: any) => unit,
      ellipsis: true,
    },
    {
      align: "center",
      width: "10%",
      title: "Room No/Bedspace",
      dataIndex: "roomName",
      key: "roomName",
      render: (roomName: any) => roomName,
      ellipsis: true,
    },
    {
      align: "center",
      width: "10%",
      title: "Requestor",
      dataIndex: "requestorName",
      key: "requestorName",
      render: (requestor: any) => requestor,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Items",
      width: "10%",
      dataIndex: "items",
      key: "items",
      render: (items: any) => {
        if (items.length === 0) {
          return <p className={classes.itemsList}>-</p>;
        } else {
          return items.map((item: any) => {
            return (
              <p
                className={classes.itemsList}
                title={`${item.item.name} - ${item.quantity}`}
              >
                {item.item.name} - {item.quantity}
              </p>
            );
          });
        }
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Description",
      width: "10%",
      dataIndex: "description",
      key: "description",
      render: (description: any) => (description ? description : "-"),
      ellipsis: true,
    },
  ];

  const handleTableChange = (pagination: any) => {
    setOperation({
      ...tableOpt,
      page: pagination.current,
      limit: pagination.pageSize,
    });
    fetchOrders({
      filter: filteredValue,
      search,
      limit: pagination.pageSize,
      page: pagination.current,
    });
  };

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  useEffect(() => {
    let defaultVal = {
      catalogue: CATALOGUE_ALL,
      campusId: ALL_CAMPUS_ID,
      dateRange: [],
      allOrder: false,
    };
    if (!isFilterVisible) {
      let localFilter = localStorage.getItem("filterValues");
      var tempValues = JSON.parse(localStorage.getItem("filterValues")!);
      if (tempValues?.catalogue === "Transport") {
        tempValues.catalogue = "Transport";
        setAllOrderForPt(true);
      }
      let preservedFilterVal = localFilter
        ? JSON.parse(localFilter)
        : defaultVal;
      let dateRange = preservedFilterVal.dateRange?.length
        ? preservedFilterVal.dateRange.map((date: any) => moment(date))
        : [];
      form.setFieldsValue({ ...preservedFilterVal, dateRange });
    }
  }, [isFilterVisible]);
  const onFilterSubmit = (values: any) => {
    const finalFilterValues = {
      ...values,
      ...tempFilterValues,
    };
    setOperation({
      ...tableOpt,
      page: DEFAULT_PAGE,
    });
    fetchOrders({
      filter: finalFilterValues,
      search,
      limit,
      page: DEFAULT_PAGE,
    });
    setFilteredValue(finalFilterValues);
    localStorage.setItem(
      "filterValues",
      JSON.stringify({ ...finalFilterValues })
    );
    setIsFilterVisible(false);
  };
  const onResetClick = () => {
    setAllOrderForPt(false);
    setOperation({
      ...tableOpt,
      page: DEFAULT_PAGE,
    });
    fetchOrders({
      filter: {
        catalogue: CATALOGUE_ALL,
        campusId: ALL_CAMPUS_ID,
        building: [],
        dateRange: [],
        allOrder: false,
      },
      search,
      limit,
      page: DEFAULT_PAGE,
    });
    setFilterCampus(ALL_CAMPUS_ID);
    form.setFieldsValue({
      catalogue: CATALOGUE_ALL,
      campusId: ALL_CAMPUS_ID,
      building: [],
      dateRange: [],
      allOrder: false,
    });
    localStorage.removeItem("filterValues");
    setFilteredValue({
      catalogue: CATALOGUE_ALL,
      campusId: ALL_CAMPUS_ID,
      building: [],
      dateRange: [],
      allOrder: false,
    });
    setTempFilterValues({});
  };

  const onRadioChange = (e: any) => {
    if (e.target.value === "Transport") {
      setAllOrderForPt(true);
    } else {
      setAllOrderForPt(false);
    }
    setTempFilterValues({ ...tempFilterValues, catalogue: e.target.value });
  };

  const handleAllOrder = (value: any) => {
    const ourDate = moment().toDate();
    const pastDate = moment().subtract(7, "days").toDate();
    const isDateAvailable =
      form.getFieldValue("dateRange") !== null &&
        form.getFieldValue("dateRange")?.length === 2
        ? true
        : false;
    if (!isDateAvailable && !filteredValue.allOrder) {
      form.setFieldsValue({
        dateRange: [moment(pastDate), moment(ourDate)],
      });
    }
    setTempFilterValues({ ...tempFilterValues, allOrder: value });
  };

  const handleFilterVisibleChange = (visible: any) => {
    setIsFilterVisible(visible);
  };

  const disabledDate = (current: any) => {
    return current && current > moment().endOf("day");
  };

  const filters = () => {
    return (
      <Form
        form={form}
        name="basic"
        onFinish={onFilterSubmit}
        className={classes.dashboardFilter}
      >
        <Form.Item
          wrapperCol={{ lg: 16 }}
          labelCol={{ lg: 8 }}
          name={"catalogue"}
          label={"Catalogue"}
          labelAlign="left"
        >
          <Radio.Group
            onChange={onRadioChange}
            value={filteredValue.catalogue}
            className={classes.reqTypeFilter}
          >
            <Radio className="defaultRadio" value={CATALOGUE_ALL}>All</Radio>
            <Radio className="defaultRadio" value={"Sharps"}>Sharps</Radio>
            <Radio className="defaultRadio" value={"Equipment"}>Equipment</Radio>
            <Radio className="defaultRadio" value={"Transport"}>Transport</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          wrapperCol={{ lg: 16 }}
          labelCol={{ lg: 8 }}
          name={"campusId"}
          label={"Campus"}
          labelAlign="left"
        >
          <Select
            className={classes.campusSelect}
            showSearch
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            onChange={(val: any) => {
              const campusSpecificBuildings: any = buildings.filter(
                (building: any) => building.campusId === val
              );
              if (!!campusSpecificBuildings.length || val === ALL_CAMPUS_ID)
                form.setFieldsValue({ building: [] });
              else form.setFieldsValue({ building: [] });
              setFilterCampus(val);
            }}
            filterOption={(input: any, option: any) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {campuses.map((campus: any) => {
              return <Option value={campus.id}>{campus.name}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          wrapperCol={{ lg: 16 }}
          labelCol={{ lg: 8 }}
          name={"building"}
          label={"Building"}
          labelAlign="left"
        >
          <Select
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            placeholder={!!buildingList.length ? "All" : "No buildings"}
            mode="multiple"
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No buildings"
                style={{ margin: "10px 0px" }}
              />
            }
            className={classes.campusSelect}
            showSearch
            filterOption={(input: any, option: any) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={filterCampus === ALL_CAMPUS_ID}
          >
            {buildingList.map((building: any) => {
              return (
                <Option value={building.buildingName}>
                  {building.buildingName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          wrapperCol={{ lg: 16 }}
          labelCol={{ lg: 8 }}
          name="dateRange"
          label={"Date Range"}
          labelAlign="left"
        >
          <RangePicker
            style={{ width: "100%" }}
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            disabledDate={disabledDate}
            format={ADMIN_DATE_FORMAT}
          />
        </Form.Item>
        {!allOrderForPt ? (
          <Form.Item
            wrapperCol={{ lg: 16 }}
            labelCol={{ lg: 8 }}
            name={"allOrder"}
            label={"All Order"}
            labelAlign="left"
            initialValue={filteredValue.allOrder}
            valuePropName="checked"
          >
            <Switch className="defaultSwitch" onChange={handleAllOrder} />
          </Form.Item>
        ) : null}

        <div className={classes.dashboardFilterFooter}>
          <Button onClick={onResetClick} type="primary">
            Reset Filter
          </Button>
          <Button htmlType="submit" type="primary">
            Apply Filter
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <>
      <Helmet>
        <title>Dashboard | Admin Portal</title>
      </Helmet>
      <section className={classes.dashBoardContainer}>
        <Title className={classes.titleText} level={5}>
          Dashboard
        </Title>
        {fetchErrorMsg ? (
          <Alert
            className={`fadeIn ${classes.alertContainer}`}
            message={fetchErrorMsg}
            type="error"
            closable
            afterClose={clearFetchError}
          />
        ) : null}
        <Row className={classes.filtersContainer}>
          <div style={{ display: "flex" }}>
            <Button className={classes.filterBtn} onClick={searchClick}>
              <img
                src={searchImg}
                alt="searchIcon"
                className={classes.btnImg}
              />
            </Button>
            {searchEnabled && (
              <Input
                value={tableOpt.search}
                className={`${classes.searchContainer} slideOut`}
                allowClear={true}
                onChange={onSearch}
                placeholder="Search by Req Id / Unit / Requestor/ Items"
              />
            )}
          </div>
          <Popover
            placement="bottomLeft"
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            title={false}
            content={filters}
            trigger="click"
            visible={isFilterVisible}
            onVisibleChange={handleFilterVisibleChange}
            overlayStyle={{ minWidth: 450 }}
          >
            <Button className={classes.filterBtn}>
              <img
                src={filterImg}
                alt="filterIcon"
                className={classes.btnImg}
              />
            </Button>
          </Popover>
          <Popover
            placement="right"
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            title={false}
            content={
              <Summary
                summary={summary}
                errorMsg={errorMessage}
                dataConfig={summaryConfig}
              />
            }
            trigger="click"
          >
            <Button className={classes.filterBtn}>
              <img src={sigmaImg} alt="filterIcon" className={classes.btnImg} />
            </Button>
          </Popover>
          <div className={classes.appliedFilters}>
            <span>
              <b>Active filters:&nbsp;</b>
            </span>
            <AppliedFiltersBreadCrumb
              {...filteredValue}
              campusName={
                campuses.find(
                  (campus: any) => filteredValue.campusId === campus.id
                )?.name
              }
            />
          </div>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Table
              className="dashboardTable"
              dataSource={orders}
              loading={ordersLoading}
              columns={columns}
              onChange={handleTableChange}
              size={"small"}
              pagination={{
                current: page,
                pageSize: limit,
                total: totalCount,
                pageSizeOptions: pageSizeOptions,
                showSizeChanger: showSizeChanger,
              }}
            />
          </Col>
        </Row>
      </section>
      <ReassignUserModal
        showModal={!!showQuickAssignModal?.reqId}
        toggleModal={() => setShowQuickAssignModal({})}
        reqId={showQuickAssignModal?.reqId}
        assignedUsr={showQuickAssignModal?.assignedTo}
      />
    </>
  );
};

const mapStateToProps = ({
  dashboardState,
  adminAuthentication,
}: IRootState) => ({
  ordersLoading: dashboardState.loading,
  orders: dashboardState.orders,
  totalCount: dashboardState.totalCount,
  fetchErrorMsg: dashboardState.fetchErrorMessage,
  summary: dashboardState.summary,
  errorMessage: dashboardState.errorMessage,
  user: adminAuthentication.account,
  campuses: dashboardState.campuses,
  buildings: dashboardState.buildings,
  dashboardState,
});
const mapDispatchToProps = {
  fetchOrders,
  clearMessage,
  fetchSummaryCounts,
  updateOrder,
  fetchCampuses,
  fetchBuildings,
  addNewOrderToSummaryCount,
};
export default connect(mapStateToProps, mapDispatchToProps)(DashBoardPage);

interface DashboardFilters {
  catalogue: string;
  campusId: number;
  campusName: string;
  dateRange?: Array<Moment>;
  allOrder: boolean;
  building: string;
}
const AppliedFiltersBreadCrumb = ({
  campusId,
  campusName,
  building,
  catalogue,
  allOrder,
}: DashboardFilters) => {
  return (
    <span>
      {campusName ? (
        <Tooltip title="campus">{` ${campusId === 1 ? "All campus" : campusName} >`}</Tooltip>
      ) : (
        ""
      )}
      {building ? (
        <Tooltip title="building">{` ${building.length === 0 ? "All building" : building} >`}</Tooltip>
      ) : (
        " No buildings >"
      )}
      {catalogue ? (
        <span>
          <Tooltip title="catalogue">{` ${catalogue === CATALOGUE_ALL ? "All catalogue" : catalogue} >`}</Tooltip>
        </span>
      ) : (
        ""
      )}
      <span>{<span> {allOrder ? "All orders" : "Active orders"}</span>}</span>
    </span>
  );
};
