import React, { useState } from "react";
import classes from "./data-import.module.scss";
import { Upload, Button, Alert, Row } from "antd";
import { adminRootInstance } from "../../../common/config/axios-config";
import { BUTTON_GREEN_HEX, GREEN_THEME_HEX } from "../../../common/shared-constants";
import { CSVLink } from "react-csv";
import { FileOutlined } from "@ant-design/icons";
import UploadImg from "../../../images/upload.png";
interface DataImportInterface {
  apiUrl: string;
  onSuccess?: (obj: any) => void;
  onFailure?: (obj: any) => void;
  templateFile?: any;
}

const DataImport = (compProps: DataImportInterface) => {
  const {
    apiUrl,
    onSuccess = () => { },
    onFailure = () => { },
    templateFile,
  } = compProps;
  const [fileList, setFileList] = useState<any>([]);
  const [uploading, setUploading] = useState(false);
  const [validationMsg, setValidationMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const clearStates = () => {
    setSuccessMsg("");
    setErrorMsg("");
    setFileList([]);
  };

  const clearMessages = () => {
    setErrorMsg("");
    setSuccessMsg("");
  };
  const handleUpload = async () => {
    clearMessages();
    const formData = new FormData();
    fileList.forEach((file: any) => {
      formData.append("csvFile", file);
    });
    setUploading(true);
    await adminRootInstance
      .post(apiUrl, formData, { timeout: 300000 })
      .then((res) => {
        setUploading(false);
        setFileList([]);
        setErrorMsg("");
        setSuccessMsg(res?.data?.detail || "Uploaded the file successfully");
        onSuccess({
          success: true,
          data: res?.data?.detail || "Uploaded the file successfully",
        });
      })
      .catch((err) => {
        setUploading(false);
        setSuccessMsg("");
        setErrorMsg(
          err.response?.data?.detail ||
          err.response?.data?.message ||
          "Something went wrong!"
        );
        onFailure({
          success: false,
          data:
            err.response?.data?.detail ||
            err.response?.data?.message ||
            "Something went wrong!",
        });
      });
  };
  const props = {
    onRemove(file: any) {
      clearStates();
    },
    beforeUpload(file: any) {
      if (
        ["application/vnd.ms-excel", "text/csv"].includes(file.type) ||
        file.name.toLowerCase().endsWith(".csv")
      ) {
        //condition to prevent multiple files drag and drop
        !fileList?.length && setFileList([file]);
        setSuccessMsg("");
        setUploading(false);
        setValidationMsg("");
      } else {
        setValidationMsg("Please upload CSV file Only");
      }
      return false;
    },
    fileList,
    multiple: false,
  };

  return (
    <>
      <Row justify="center" style={{ width: "80%" }}>
        {/* Sample Template */}
        {templateFile && (
          <CSVLink
            data={templateFile.data}
            filename={templateFile.fileName}
            title="File Template"
          >
            <Button
              onFocus={(e: any) => e.target.blur()}
              icon={
                <FileOutlined
                  style={{
                    width: "140px",
                    fontSize: "60px",
                    textAlign: "center",
                    position: "relative",
                    color: BUTTON_GREEN_HEX,
                    marginBottom: "15px",
                  }}
                />
              }
              className={classes.downloadFileBtn}
            >
              Download Template
            </Button>
          </CSVLink>
        )}

        {/* Data upload  */}
        <Upload
          className={classes.dataImport}
          {...props}
          multiple={false}
          accept=".csv"
        >
          <Button
            disabled={fileList.length >= 1}
            icon={
              <img
                alt="Upload File"
                src={UploadImg}
                style={{
                  width: "75px",
                  fontSize: "60px",
                  textAlign: "center",
                  position: "relative",
                  color: BUTTON_GREEN_HEX,
                  marginBottom: "15px",
                }}
              />
            }
            className={classes.selectFileBtn}
          >
            Select File
          </Button>
        </Upload>
      </Row>
      {successMsg !== "" && (
          <div className={classes.checkFade}>
            <Alert
              style={{ marginTop: 25 }}
              message={successMsg}
              showIcon
              closable
              type="success"
              className="fadeIn"
              onClose={() => setSuccessMsg("")}
            />
          </div>
        )}
      {errorMsg !== "" && (
        <div className={classes.checkFade}>
          <Alert
            style={{ marginTop: 25 }}
            message={errorMsg}
            showIcon
            closable
            type="error"
            className="fadeIn"
            onClose={() => setErrorMsg("")}
          />
        </div>
      )}
      <div className={classes.uploadValidate}>{validationMsg}</div>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={fileList?.length === 0}
        loading={uploading}
        style={{ marginTop: 25 }}
        className={`${classes.uploadBtn} nextBtn`}
      >
        {uploading ? "Uploading" : "Start Upload"}
      </Button>
    </>
  );
};

export default DataImport;
