/* eslint-disable react-hooks/exhaustive-deps*/
import { Button, message } from "antd";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { adminRootInstance } from "../../../../../common/config/axios-config";
import { overalltablecolumns } from "../AIMOverallReport/AIMTableView";
import exportImg from "../../../../../images/upload.png";
import classes from "./AIMDownloadCSV.module.scss";
import { dateRenderer, nullRenderer } from "../../AIMReports/utils/Renderer";
import { RETIRED_ASSET } from "../.././/.//../../../common/shared-constants";
import { checkintablecolumns } from "../CheckinReport/CheckinTableView";
import { decontablecolumns } from "../DeconReport/DeconTableView";
import { rentaltablecolumns } from "../RentalReport/RentalTableView";

interface DownloadCSVInterface {
  filterData: any;
  reportType: "all" | "rental" | "checkin" | "decon" | "AIM Dashboard";
  reportData?: any;
  searchText?: any;
}

export default function AIMDownloadCSV(props: DownloadCSVInterface) {
  const { filterData: reportsFilter, reportType, searchText: search } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [csvLinkData, setCsvLinkData] = useState<any>();
  const csvLink: any = useRef();

  const getAIMDashboardData = async () => {
    //hit csv api using th filter data, if successful - set the csv file config to it - else clear it
    setLoading(true);
    let rawData: any = await AIMDashboardAPICall(reportsFilter, search);
    if (rawData?.success) {
      setLoading(false);
      const preProcessor = (configFile: any) => {
        const masterConfigObj: any = {};
        const labelObj = configFile.map((configObj: any) => {
          masterConfigObj[configObj.dataIndex] = configObj;
          return { label: configObj.title, key: configObj.dataIndex };
        });
        const dataObj = rawData.data.assets.map((reportObj: any) => {
          let tempObj: any = {};
          Object.keys(reportObj).forEach((key, index) => {
            tempObj[key] =
              masterConfigObj[key]?.render(
                reportObj[key],
                reportObj,
                index,
                true
              ) || masterConfigObj[key];
          });
          return tempObj;
        });
        setCsvLinkData({
          labelData: labelObj,
          csvData: dataObj,
          fileName: `${reportType}.csv`,
        });
      };
      const commonObj = {
        align: "center",
        ellipsis: true,
      };
      const columns: any = [
        {
          ...commonObj,
          title: "Equipment Name",
          dataIndex: "equipmentName",
          key: "equipmentName",
          width: 65,
          render: nullRenderer,
        },
        {
          ...commonObj,
          title: "Control number",
          dataIndex: "controlNumber",
          width: 65,
          render: nullRenderer,
        },
        {
          ...commonObj,
          title: "Serial number",
          dataIndex: "serialnumber",
          width: 65,
          render: nullRenderer,
        },
        {
          ...commonObj,
          title: "Campus",
          dataIndex: "campus",
          width: 65,
          ellipsis: false,
          render: nullRenderer,
        },
        {
          ...commonObj,
          title: "Specialty",
          dataIndex: "specialty",
          width: 40,
          render: (isSpecialty: any) => (isSpecialty ? "Yes" : "No"),
        },
        {
          ...commonObj,
          title: "Rental",
          dataIndex: "rental",
          width: 40,
          render: (isRental: any) => (isRental ? "Yes" : "No"),
        },
        {
          ...commonObj,
          title: "Rotation Count",
          dataIndex: "assetRotationCount",
          width: 60,
          render: nullRenderer,
        },
        {
          ...commonObj,
          title: "Last Location",
          dataIndex: "lastLocation",
          width: 60,
          ellipsis: false,
          render: nullRenderer,
        },
        {
          ...commonObj,
          title: "Ownership Type",
          dataIndex: "ownershipType",
          width: 60,
          render: nullRenderer,
        },
        {
          ...commonObj,
          title: "Status",
          dataIndex: "status",
          width: 60,
          render: (value: any, record: any) =>
            record.deleted ? RETIRED_ASSET : value || "-", //Check if deleted flag is there & show status
        },
        {
          ...commonObj,
          title: "Checkout",
          dataIndex: "checkOut",
          width: 60,
          render: dateRenderer,
        },
        {
          ...commonObj,
          title: "Pickup ready",
          dataIndex: "pickupReady",
          width: 60,
          render: dateRenderer,
        },
        {
          ...commonObj,
          title: "Check In",
          dataIndex: "checkIn",
          width: 60,
          render: dateRenderer,
        },
        {
          ...commonObj,
          title: "Clean Start",
          dataIndex: "cleanStart",
          width: 60,
          render: dateRenderer,
        },
        {
          ...commonObj,
          title: "Clean End",
          dataIndex: "cleanEnd",
          width: 60,
          render: dateRenderer,
        },
      ];
      preProcessor(columns);

      csvLink.current.link.click();
    } else {
      setLoading(false);
      message.error(rawData.errorMessage);
    }
  };

  const getReportsData = async () => {
    //hit csv api using th filter data, if successful - set the csv file config to it - else clear it
    setLoading(true);
    let rawData: any = await ReportsAPICall(reportType, reportsFilter);
    if (rawData?.success) {
      setLoading(false);

      const preProcessor = (configFile: any) => {
        const masterConfigObj: any = {};
        const labelObj = configFile.map((configObj: any) => {
          masterConfigObj[configObj.dataIndex] = configObj;
          return { label: configObj.title, key: configObj.dataIndex };
        });
        const dataObj = rawData.data.assets.map((reportObj: any) => {
          let tempObj: any = {};
          Object.keys(reportObj).forEach((key, index) => {
            tempObj[key] =
              masterConfigObj[key]?.render(
                reportObj[key],
                reportObj,
                index,
                true
              ) || masterConfigObj[key];
          });
          return tempObj;
        });
        setCsvLinkData({
          labelData: labelObj,
          csvData: dataObj,
          fileName: `${reportType}-overall.csv`,
        });
      };
      switch (reportType) {
        case "all":
          preProcessor(overalltablecolumns);
          break;
        case "checkin":
          preProcessor(checkintablecolumns);
          break;
        case "rental":
          preProcessor(rentaltablecolumns);
          break;
        case "decon":
          preProcessor(decontablecolumns);
          break;
        default:
          break;
      }
      csvLink.current.link.click();
    } else {
      setLoading(false);
      message.error(rawData.errorMessage);
    }
  };
  return (
    <>
      {reportType === "AIM Dashboard" ? (
        <Button
          type="primary"
          onClick={() => getAIMDashboardData()}
          className={classes.filterBtn}
        >
          <img
            alt="exportIcon"
            style={{
              filter: "brightness(0) invert(1) drop-shadow(0 0 0px white)",
              transform: "rotate(180deg)",
            }}
            className={classes.btnImg}
            src={exportImg}
          />
        </Button>
      ) : (
        <Button
          type="primary"
          onClick={() => getReportsData()}
          loading={loading}
        >
          Download CSV
        </Button>
      )}
      {csvLinkData && (
        <CSVLink
          data={csvLinkData.csvData}
          headers={csvLinkData.labelData}
          filename={csvLinkData.fileName}
          ref={csvLink}
        />
      )}
    </>
  );
}

const AIMDashboardAPICall = async (reportsFilter: any, search: any) => {
  const queryParams = {
    campus: reportsFilter.campusId,
    ...(reportsFilter.status !== "ALL" ? { status: reportsFilter.status } : {}),
    location: reportsFilter.location,
    ...(reportsFilter.speciality !== "both"
      ? { speciality: reportsFilter.speciality }
      : {}),
    ...(reportsFilter.rental !== "both"
      ? { rental: reportsFilter.rental }
      : {}),
    searchKeyword: search || "",
    ...(reportsFilter.ownershipType !== 'ALL' ? { ownershipType: reportsFilter.ownershipType } : {})
  };
  return await adminRootInstance
    .get("aim/assets", {
      params: queryParams,
    })
    .then((res: any) => {
      if (res?.data) return { ...res.data, success: true };
    })
    .catch((err: any) => {
      return { success: false, errorMessage: err?.response?.data?.message };
    });
};

const ReportsAPICall = async (type: string, reportsFilter: any) => {
  const {
    //AIM report
    checkin,
    checkout,
    itemType,
    status,
    speciality,
    campusId,
    //Check-in report
    from,
    to,
  } = reportsFilter;
  return await adminRootInstance
    .get(`aim/reports/${type}`, {
      params: {
        //AIM Overall report
        ...(checkin ? { from: checkin.format("YYYY-MM-DD") } : {}),
        ...(checkout ? { to: checkout.format("YYYY-MM-DD") } : {}),
        ...(itemType ? { itemId: itemType } : {}),
        ...(status ? { status: status } : {}),
        ...(speciality && speciality !== "all"
          ? { speciality: speciality }
          : {}),
        ...(campusId ? { campusId: campusId } : {}),
        //Check in report
        ...(from ? { from: from.format("YYYY-MM-DD") } : {}),
        ...(to ? { to: to.format("YYYY-MM-DD") } : {}),
      },
    })
    .then((res: any) => {
      if (res?.data) return { ...res.data, success: true };
    })
    .catch((err) => {
      return { success: false, errorMessage: err?.response?.data?.message };
    });
};
